import React, { useEffect, useState } from 'react';
import { Paper, Stack, styled, Typography } from '@mui/material';
import { Location } from 'react-router-dom';
import { useHistory } from 'react-router';

import { Headline3 } from '../Headline3';
import { ActionBar } from '../ActionBar/ActionBar';
import ListOrTabNav, { ListOrTabNavProps, NavVariant } from '../ListOrTabNav/ListOrTabNav';

export interface PageProps {
	headline?: unknown;
	text?: string;
	children?: React.ReactNode;
	actionBar?: React.ReactElement<ActionBar>;
	style?: unknown;
	id?: unknown;
	variant?: unknown;
}
export interface MultiPagesProps {
	leftSpace?: number | string;
	navItems?: ListOrTabNavProps['navItems'];
	innerPages?: React.ReactElement[];
	activePageId?: number;
	location?: Location;
}

function Content({ headline, text, children, actionBar, ...rest }: PageProps) {
	return (
		<Paper
			id="page-content"
			variant="outlined"
			sx={{ display: 'flex', flexDirection: 'column', p: 3, gap: 5 }}
			{...rest}
		>
			<Stack spacing={2}>
				<Headline3>{headline}</Headline3>
				{!!text && <Typography variant="body1">{text}</Typography>}
			</Stack>
			{children}
			{actionBar}
		</Paper>
	);
}

function Page(props: PageProps & MultiPagesProps) {
	/* overloading Page with props for a level 2 Nav and active content of multi inner pages */
	const { leftSpace = 280, navItems, innerPages, location, activePageId } = props;

	const history = useHistory();
	const [activeId, setActiveId] = useState<number | undefined>();

	useEffect(() => {
		if (activePageId !== null && activePageId !== undefined) {
			setActiveId(activePageId);
		}
	}, [activePageId]);

	useEffect(() => {
		if (navItems?.[activeId]?.link && navItems[activeId].link !== location?.pathname) {
			history?.push(navItems[activeId].link);
		}
	}, [activeId]);

	/* single Content Page only content without Nav */
	if (!props?.navItems) return <Content {...props} />;

	const width = typeof leftSpace === 'string' ? leftSpace : `${leftSpace}px`;

	const variant: NavVariant = 'tab';
	const navProps = { navItems, width, setActiveId, activeId, variant };

	return (
		<Paper variant="outlined" sx={{ display: 'flex', flexWrap: 'wrap' }}>
			<ListOrTabNav {...navProps} />
			<ActiveContent sx={{ width: '100%' }}>{innerPages[activeId]}</ActiveContent>
		</Paper>
	);
}

const ActiveContent = styled(Paper)({
	boxShadow: 'none',
	'*#page-content': {
		borderWidth: 0
	}
});

export type Page = typeof Page;

// eslint-disable-next-line import/no-default-export
export default Page;
