export enum ChargebeePlans {
	RELAY_FREE = 'relay-free',
	RELAY_BASIC = 'relay-basic',
	RELAY_PREMIUM = 'relay-premium',
	RELAY_PREMIUM_DB = 'relay-premium-db'
}

export enum ChargebeeAddons {
	PROPERTIES_BASIC = 'additional_properties_basic',
	PROPERTIES_PREMIUM = 'additional_properties_premium'
}

export enum ChargebeePlansV2 {
	RELAY_STARTER = 'relay-starter',
	RELAY_SMALL = 'relay-small',
	RELAY_MEDIUM = 'relay-medium',
	RELAY_PRO = 'relay-pro',
	RELAY_ANNUAL = 'relay-annual'
}

export const { RELAY_SMALL, RELAY_MEDIUM, RELAY_PRO, RELAY_STARTER, RELAY_ANNUAL } =
	ChargebeePlansV2;
export const { RELAY_FREE, RELAY_BASIC, RELAY_PREMIUM, RELAY_PREMIUM_DB } = ChargebeePlans;

export const ALL_PRICES = {
	[RELAY_SMALL]: 1900,
	[RELAY_MEDIUM]: 5900,
	[RELAY_PRO]: 19900,
	[RELAY_FREE]: 0,
	[RELAY_BASIC]: 4900,
	[RELAY_PREMIUM]: 14900
};

export const ORDER_CREDITS = {
	[RELAY_SMALL]: 5,
	[RELAY_MEDIUM]: 25,
	[RELAY_PRO]: 100
};

export enum ChargebeeAddonsV2 {
	ADDITIONAL_ORDER = 'unit-based-additional-order'
}

export enum ChargebeeInvoiceStatus {
	PENDING = 'pending'
}

export enum ChargebeeSubscriptionStatus {
	ACTIVE = 'active',
	IN_TRIAL = 'in_trial',
	CANCELLED = 'cancelled'
}

export enum ChargebeeEventTypes {
	SUBSCRIPTION_CHANGED = 'subscription_changed',
	SUBSCRIPTION_REACTIVATED = 'subscription_reactivated',
	SUBSCRIPTION_CREATED = 'subscription_created',
	SUBSCRIPTION_CANCELLED = 'subscription_cancelled',
	SUBSCRIPTION_ACTIVATED = 'subscription_activated',
	PAYMENT_SOURCE_ADDED = 'payment_source_added'
}
